<template>
	<div class="login">
		<div class="login-weaper">
			<img class="titleClass" src="@/assets/imgs/title.png" alt="" />
			<div class="login-weaper-content">
				<img src="@/assets/imgs/loginImg.png" alt="" />
				<div class="login-main">
					<p class="login-title">欢迎登录</p>
					<div class="changeLogin clearfix">
						<span class="login-subTitle float-l" :class="{ activeClass: activeVal === 1 }" @click="activeVal = 1">用户名登录</span>
						<!-- <span class="login-subTitle float-l" :class="{ activeClass: activeVal === 2 }" @click="activeVal = 2">手机验证码登录</span> -->
					</div>
					<el-form class="el-form login-form" :rules="rules" ref="ruleForm" :model="ruleForm">
						<el-form-item prop="name">
							<el-input type="text" :placeholder="'请输入账号/手机号'" v-model.trim="ruleForm.name" clearable autocomplete="off"> </el-input>
						</el-form-item>
						<el-form-item style="margin-bottom: 14px" prop="password">
							<el-input
								type="password"
								clearable
								:placeholder="'请输入密码'"
								v-model="ruleForm.password"
								autocomplete="off"
								:show-password="true"
							></el-input>
						</el-form-item>
						<!-- <p v-if="activeVal === 1" class="forgetPwd" @click="activeVal = 2">忘记密码</p> -->
						<el-form-item style="margin-top: 40px">
							<el-button type="primary" class="login-submit" @click="submitForm" :loading="submit.loading">
								<span>登录</span>
							</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<div class="login-weaper-footer">
				<p style="font-size: 14px; margin-bottom: 8px">主办单位：基层卫生健康智慧服务项目办公室</p>
				<span>版权所有：青岛英凯利信息科技有限公司</span> |
				<span>备案号：辽ICP备19018053号-1</span>
			</div>
		</div>
	</div>
</template>

<script>
import { Session } from '@/utils/storage';
export default {
	name: 'login',
	data() {
		return {
			submit: {
				loading: false,
			},
			ruleForm: {
				name: '',
				password: '',
			},
			rules: {
				name: [{ required: true, message: '请输入账号/手机号', trigger: 'blur' }],
				password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
			},
			activeVal: 1,
		};
	},
	computed: {
		// 获取布局配置信息
		getThemeConfig() {
			return this.$store.state.themeConfig.themeConfig;
		},
	},
	created() {},
	mounted() {
		//回车登陆
		document.onkeydown = () => {
			var key = window.event.keyCode;
			if (key == 13 && this.$route.path === '/login') {
				this.submitForm();
			}
		};
	},
	methods: {
		// 登录按钮点击
		submitForm() {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.submit.loading = true;
					this.$http
						.post('/management-api/v1/Sessions.json', this.ruleForm)
						.then((res) => {
							if (res.data && res.data.success) {
								this.getUserInfo(res.data._links['UserProfiles#load'].href);
								// 存储 token 到浏览器缓存
								Session.set('token', res.data.collection[0].token);
								Session.set('loginInfo', res.data.collection[0]);
								Session.set('apiInfo', res.data._links);
							}
						})
						.catch((e) => {
							this.submit.loading = false;
						});
				} else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
		getUserInfo(link) {
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						this.submit.loading = false;
						if (!res.data.collection || res.data.collection.length == 0) return this.$message.error('用户信息获取失败，请联系管理员');
						// 存储用户信息到浏览器缓存
						const userInfos = {
							...res.data.collection[0],
						};
						Session.set('userInfo', userInfos);
						this.$message.success('登录成功'); 
						this.$router.push('/');
						this.submit.loading = false;
						window.location.reload();
					}
				})
				.catch((e) => {});
		},
	},
	destroyed() {},
};
</script>

<style scoped lang="scss">
.login {
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	position: relative;
	&-weaper {
		width: 100%;
		height: 60%;
		background: url('../../assets/imgs/bg.png') no-repeat center;
		background-size: cover;
		&-content {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			box-shadow: 0px 6px 32px 0px rgba(81, 81, 81, 0.06);
			width: 736px;
			height: 468px;
			margin: auto;
			display: flex;
			overflow: auto;
			background-color: #fff;
			border-radius: 8px;
			img {
				width: 300px;
			}
			.login-main {
				flex: 1;
				padding: 0 52px 37px;

				.login-title {
					color: #333;
					margin-bottom: 32px;
					font-weight: 500;
					font-size: 28px;
					text-align: left;
					line-height: 28px;
					margin-top: 72px;
					flex-wrap: nowrap;
				}
				.changeLogin {
					color: #999;
					margin-bottom: 32px;
					span {
						display: inline-block;
						font-size: 18px;
						line-height: 38px;
						margin-right: 32px;
						cursor: pointer;
					}
					.activeClass {
						color: #1db9b1;
						border-bottom: 2px solid #1db9b1;
					}
				}
				.login-form {
					.el-form-item {
						margin-bottom: 20px;
						.el-input {
							width: 100%;
						}
						/deep/ .el-input__inner {
							line-height: 48px;
							height: 48px;
						}
						/deep/ .el-input__icon {
							line-height: 48px;
							height: 48px;
						}
						/deep/ .el-button {
							padding: 15px 16px;
							border-radius: 8px;
						}
						.login-submit {
							width: 100%;
							letter-spacing: 2px;
						}
					}
					.forgetPwd {
						font-size: 13px;
						line-height: 13px;
						height: 13px;
						color: #999;
						text-align: end;
					}
				}
			}
		}
		.titleClass {
			position: absolute;
			top: 40px;
			left: 40px;
		}
		&-footer {
			width: 100%;
			position: absolute;
			bottom: 20px;
			left: 40px;
			color: #999;
			font-size: 12px;
			line-height: 12px;
			// height: 13px;
			text-align: center;
			margin: auto;
		}
	}
}
</style>
